<template>
    <div class="demo">
        <div class="progress-bar-container" ref="box" @touchmove="touchmoveFunc($event)" @touchend="touchendFunc()">
            <div class="total-progress-container" ref="wrapper" @click="clickWrapperFunc($event)">
                <div class="current-progress-container" ref="fill"></div>
                <div class="bar" ref="mySlider" @touchstart="touchstartFunc()"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "demo",
    data: function () {
        return {
            drag:0,                             // 是否允许拖拽Slider滑块，0：不允许；1：允许
        }
    },
    methods: {
        touchstartFunc: function () {
            this.drag = 1;
        },
        touchmoveFunc: function (e) {
            if(this.drag == 1){
                if (e.touches[0].pageX > (this.$refs.box.offsetLeft + this.$refs.wrapper.offsetLeft + this.$refs.wrapper.offsetWidth - this.$refs.mySlider.offsetWidth)) {
                    this.$refs.mySlider.style.left = this.$refs.wrapper.offsetWidth - this.$refs.mySlider.offsetWidth + "px";
                    this.$refs.fill.style.width = this.$refs.wrapper.offsetWidth + "px";
                } else if (e.touches[0].pageX < (this.$refs.box.offsetLeft + this.$refs.wrapper.offsetLeft)) {
                    this.$refs.mySlider.style.left = "0px";
                    this.$refs.fill.style.width = "0px";
                }else{
                    this.$refs.mySlider.style.left = (e.touches[0].pageX - this.$refs.box.offsetLeft - this.$refs.wrapper.offsetLeft) + "px";
                    this.$refs.fill.style.width = (e.touches[0].pageX - this.$refs.box.offsetLeft) + "px";
                }
            }
            
        },
        touchendFunc: function () {
            this.drag = 0;
        },
        clickWrapperFunc: function (e) {
            if (e.target !== this.$refs.mySlider) {
                if (e.offsetX > (this.$refs.wrapper.offsetWidth - this.$refs.mySlider.offsetWidth)) {
                    this.$refs.mySlider.style.left = this.$refs.wrapper.offsetWidth - this.$refs.mySlider.offsetWidth + "px";
                    this.$refs.fill.style.width = this.$refs.wrapper.offsetWidth - this.$refs.mySlider.offsetWidth + "px";
                } else {
                    this.$refs.mySlider.style.left = e.offsetX + "px";
                    this.$refs.fill.style.width = e.offsetX + "px";
                }
            }
        }

    }
}
</script>

<style scoped>
.progress-bar-container{
    width: 6.2rem;
    height: 0.28rem;
    background-color: brown;
    position: relative;
    margin: 0 auto;
}

/* ===================================== */

.total-progress-container{
    width: 5.8rem;
    height: 0.13rem;
    background-color: gray;
    position: absolute;
    top: 0.08rem;
    left: 0.2rem;
    border-radius: 0.05rem;
}
.current-progress-container{
    width: 0;
    height: 0.13rem;
    background-color: skyblue;
    border-radius: 0.05rem;
}
.bar{
    width: 0.23rem;
    height: 0.23rem;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    top: -0.05rem;
}
</style>
