<template>
  <div class="scanQRCode">
    <router-link to="/"><van-icon name="arrow-left" size="0.8rem" color="#fff" /></router-link>
    <canvas id="lineCanvas" ref="lineCanvas" ></canvas>
    <canvas id="canvas" ref="canvas" hidden></canvas>
    <!-- <div id="output" v-show="outputContainer">
      <div id="outputMessage" v-show="outputMessage">{{outputData?outputData:'No QR code detected.'}}</div>
      <div hidden><b>Data:</b> <span id="outputData" ref="outputData">{{outputData}}</span></div>
    </div> -->

    <video ref="video" ></video>
  </div>
</template>

<script>
import { Toast } from 'vant';
// import adapter from "webrtc-adapter";
// WebRTC适配器 只需要引入就ok
import jsQR from "jsqr";
export default {
   name: 'scanQRCode',
    data: () => ({
        selectedDeviceId:'',

        loadingMessageshow:true,
        outputContainer:false,
        loadingMessage:'',
        outputMessage:true,
        outputData:'',
    }),
    created() {
      
    },
    mounted() {
      this.initdevices();
    },
    onUnmounted(){
        this.codeReader.reset();
    },
    methods: {
      initdevices(){
        // var mediaDevices = 
        navigator.mediaDevices.enumerateDevices().then((mediaDevices) =>{
            var videoInputDevices = mediaDevices.filter(mediaDevice=>{
              return mediaDevice.kind === 'videoinput';
            })
            // console.log(videoInputDevices);
            const matcher = ({ label }) => /back|trás|rear|traseira|environment|ambiente/gi.test(label);
            var selectedDevice = videoInputDevices.find(matcher) || videoInputDevices.pop();
            // this.selectedDeviceId = selectedDevice.deviceId;
            // console.log(selectedDeviceId);
            console.log('selectedDevice:',selectedDevice);
            this.initcamera(selectedDevice.deviceId);
        });


      },
      initcamera(selectedDeviceId){
        
        // Use facingMode: environment to attemt to get the front camera on phones
        var video =this.$refs.video;
        var _that = this;
        navigator.mediaDevices.getUserMedia({ audio: false, video: { deviceId: selectedDeviceId } }).then(function(stream) {
        // navigator.mediaDevices.getUserMedia({ audio: false, video: { facingMode: { exact: "environment" } } }).then(function(stream) {
          video.srcObject = stream;
          video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
          video.play();
          requestAnimationFrame(_that.tick);
        });
      },

      drawLine(begin, end, color) {
        let lineCanvas = this.$refs.lineCanvas.getContext("2d");
        lineCanvas.beginPath();
        lineCanvas.moveTo(begin.x, begin.y);
        lineCanvas.lineTo(end.x, end.y);
        lineCanvas.lineWidth = 4;
        lineCanvas.strokeStyle = color;
        lineCanvas.stroke();
      },
      tick() {
        let canvasElement = this.$refs.canvas;
        let lineCanvasElement = this.$refs.lineCanvas;
        let canvas = this.$refs.canvas.getContext("2d");
        this.loadingMessage = "⌛ Loading video..."
        if (this.$refs.video.readyState === this.$refs.video.HAVE_ENOUGH_DATA) {
          this.loadingMessageshow = false;
          canvasElement.hidden = false;
          // this.outputContainer = true;

          lineCanvasElement.height = window.screen.availHeight;
          lineCanvasElement.width = window.screen.availWidth;
          canvasElement.height = window.screen.availHeight;
          canvasElement.width = window.screen.availWidth;


          var hb = this.$refs.video.videoHeight/window.screen.availHeight
          var wb = this.$refs.video.videoWidth/window.screen.availWidth
          if(wb > hb){
            var cvw = window.screen.availWidth;
            var cvh = this.$refs.video.videoHeight/wb;
          }
          // this.$refs.video.videoHeight

          canvas.drawImage(this.$refs.video, 0, (window.screen.availHeight-cvh)/2, cvw, cvh);
          var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
          var code = jsQR(imageData.data, imageData.width, imageData.height, {
            inversionAttempts: "dontInvert",
          });
          if (code) {
            this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#29910d");
            this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#29910d");
            this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#29910d");
            this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#29910d");

            let goods_sn = new RegExp("goods_sn=[0-9]*","g").exec(code.data);
            var sn_query = {};
            if(goods_sn != null){
              sn_query.goods_sn = goods_sn[0].replace('goods_sn=','');
            }else{
              var sn = new RegExp("sn=[0-9]*","g").exec(code.data);
              sn_query.sn = sn[0].replace('sn=','');
            }

            if(sn_query){
              Toast.loading({
                message: '识别成功',
                forbidClick: true,
              });
              this.$router.push({path:"/itemDetail",query:sn_query});
            }
            
            // console.log('codedata:',code.data);
            // this.outputMessage = false;
            // this.$refs.outputData.parentElement.hidden = false;
            // this.outputData = code.data;
          } else {
            // this.outputMessage = true;
            // this.$refs.outputData.parentElement.hidden = true;
          }
        }
        requestAnimationFrame(this.tick);
      }
    },
};

</script>
<style lang="less" scoped>
.van-icon{
    position: absolute;
    z-index: 10;
    top: 0.2rem;
    z-index: 101;
}
.scanQRCode{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
}
#canvas {
  position: absolute;
  top: 0;  
  width: 100%;
  height: 100%;
  z-index: 98;
  // background-color: black;
}
#lineCanvas {
  position: absolute;
  top: 0;  
  width: 100%;
  height: 100%;
  z-index: 100;
  // background-color: black;
}
video {
  position: absolute;
  top: 0;  
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 99;
}
</style>