<template>
  <!-- <div id="app"> -->
    <!-- <Header></Header> -->
    <chooseLang v-if="showChooseLang"></chooseLang>
    <router-view/>
  <!-- </div> -->
</template>

<script>
// import Header from './components/Header.vue'
import chooseLang from './components/chooseLang.vue'
export default {
  components: {
    // Header,
    chooseLang
  },
    created(){
        // this.$Message.config({
        //     top: 300,
        //     duration: 1
        // });
    },
    provide(){
       return {
           stopPropagation : this.stopPropagation
       }
    },
    methods:{
        stopPropagation(e) {
            e = e || window.event;
            if (e.stopPropagation) {//W3C阻止冒泡方法
                e.stopPropagation();
            }else {
                e.cancelBubble =true; //IE阻止冒泡方法
            }
        }
    },
    unmounted(){
        // this.$Message.destroy()
    },
  computed: {
    showChooseLang: function () {
      return this.$store.state.showChooseLang;
    }
  }
}
</script>

<style>
/* #app{
    background-color: #f6f6f6;
    min-height: 100vh;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
} */
.ivu-icon-ios-close-circle:before {
  left: 4px;
  top: 11px;
}
</style>
