<template>

    
    <div class="bar">
    <!-- 这两个按钮用于转换页面布局形式，使得UL布局正确显示 -->
    
    <!-- <a v-bind:class="{ 'active': layout == 'list'}" v-on:click="layout = 'list'"></a> -->
    <a :class="layouticon" @click="changelayout()"></a>
    </div>
    
    <!-- 现在有两种布局形式，选择哪种布局取决于 layout绑定 -->

    <ul v-if="layout == 'grid'" class="grid">
    <!-- 这种布局只显示缩略图片不显示文字 -->
    <li v-for="(a,i) in articles" :key="i">
    <a v-bind:href="a.url" rel="external nofollow"  target="_blank"><img v-bind:src="a.image.large" /></a>
    </li>
    </ul>

    <ul v-if="layout == 'list'" class="list">
    <!-- 这种布局显示小图片和文字 -->
    <li  v-for="(a,i) in articles" :key="i">
    <a v-bind:href="a.url" rel="external nofollow" target="_blank"><img v-bind:src="a.image.small" /></a>
    <p>{{a.title}}</p>
    </li>
    </ul>

</template>


<script>
export default {
    name: "demo2",
    data(){
        return {
            // 布局形式可能的值为grid或者list
            layout: 'grid',
            layouticon: 'grid-icon',
            articles: [{
            "title": "What You Need To Know About CSS Variables",
            // "url":
            "image": {
                "large": "//img.jbzj.com/file_images/article/201708/15-128.png",
                "small": "//img.jbzj.com/file_images/article/201708/15-128.png"
            }
            },
            {
            "title": "Freebie: 4 Great Looking Pricing Tables",
            "image": {
                "large": "//img.jbzj.com/file_images/article/201708/15-128.png",
                "small": "//img.jbzj.com/file_images/article/201708/15-128.png"
            }
            },
            {
            "title": "20 Interesting JavaScript and CSS Libraries for February 2016",
            "image": {
                "large": "//img.jbzj.com/file_images/article/201708/15-128.png",
                "small": "//img.jbzj.com/file_images/article/201708/15-128.png"
            }
            },
            {
            "title": "Quick Tip: The Easiest Way To Make Responsive Headers",
            "image": {
                "large": "//img.jbzj.com/file_images/article/201708/15-128.png",
                "small": "//img.jbzj.com/file_images/article/201708/15-128.png"
            }
            },
            {
            "title": "Learn SQL In 20 Minutes",
            "image": {
                "large": "//img.jbzj.com/file_images/article/201708/15-128.png",
                "small": "//img.jbzj.com/file_images/article/201708/15-128.png"
            }
            },
            {
            "title": "Creating Your First Desktop App With HTML, JS and Electron",
            "image": {
                "large": "//img.jbzj.com/file_images/article/201708/15-128.png",
                "small": "//img.jbzj.com/file_images/article/201708/15-128.png"
            }
            }]
        }
    },
    methods: {
        changelayout(){
            if(this.layout == 'grid'){
                this.layout = 'list';
                this.layouticon = 'list-icon';
            }else{
                this.layout = 'grid';
                this.layouticon = 'grid-icon';
                
            }
        }
    }

 };
</script>

 <style scoped>

 

 
 /* *{
  margin:0;
  padding:0;
 }
 
 body{
  font:15px/1.3 'Open Sans', sans-serif;
  color: #5e5b64;
  text-align:center;
 } */
 

 /*-------------------------
  导航栏样式
 --------------------------*/
 
 .bar{
  background-color:#03c03c;
/*  
  background-image:-webkit-linear-gradient(top, #03c03c, #00ed47);
  background-image:-moz-linear-gradient(top, #03c03c, #00ed47);
  background-image:linear-gradient(top, #03c03c, #00ed47); */
 
  box-shadow: 0 1px 1px #ccc;
  border-radius: 5px;
  width: 100vw;
  padding: 10px;
  /* margin: 45px auto 25px; */
  position:relative;
  text-align:right;
  line-height: 1;
 }
 
 .bar a{
  background: #ffe055 center center no-repeat;
  width:35px;
  height:35px;
  display:inline-block;
  text-decoration:none !important;
  margin-right:5px;
  border-radius:5px;
  cursor:pointer;
 }
 
 /* .bar a.active{
  background-color:orange;
 } */
 
 .bar a.list-icon{
  background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh7cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkYzNkFCQ0ZBMTBCRTExRTM5NDk4RDFEM0E5RkQ1NEZCIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkYzNkFCQ0ZCMTBCRTExRTM5NDk4RDFEM0E5RkQ1NEZCIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RjM2QUJDRjgxMEJFMTFFMzk0OThEMUQzQTlGRDU0RkIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RjM2QUJDRjkxMEJFMTFFMzk0OThEMUQzQTlGRDU0RkIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7h2bLqAAAAWUlEQVR42mL8////BwYGBn4GCACxBRlIAIxAA/4jaXoPEkMyjJ+A/g9MDJQBRhYg8RFqMwg8RJIUINYLFDmBUi+ADQAF1n8ofk9yIAy6WPg4GgtDMRYAAgwAdLYwLAoIwPgAAAAASUVORK5CYII=);
 }
 
 .bar a.grid-icon{
  background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh7cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjBEQkMyQzE0MTBCRjExRTNBMDlGRTYyOTlBNDdCN0I4IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjBEQkMyQzE1MTBCRjExRTNBMDlGRTYyOTlBNDdCN0I4Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MERCQzJDMTIxMEJGMTFFM0EwOUZFNjI5OUE0N0I3QjgiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MERCQzJDMTMxMEJGMTFFM0EwOUZFNjI5OUE0N0I3QjgiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4MjPshAAAAXklEQVR42mL4////h/8I8B6IGaCYKHFGEMnAwCDIAAHvgZgRyiZKnImBQsACxB+hNoDAQyQ5osQZIT4gH1DsBZABH6AB8x/JaQzEig++WPiII7Rxio/GwmCIBYAAAwAwVIzMp1R0aQAAAABJRU5ErkJggg==);
 }
 

 
 /*-------------------------
  列表布局
 --------------------------*/
 
 ul.list{
  list-style: none;
  width: 100vw;
  margin: 0 auto;
  text-align: left;
 }
 
 ul.list li{
  border-bottom: 1px solid #ddd;
  padding: 10px;
  overflow: hidden;
  cursor: pointer;
 }
 
 ul.list li img{
  width:120px;
  height:120px;
  float:left;
  border:none;
 }
 
 ul.list li p{
  margin-left: 135px;
  font-weight: bold;
  color:#6e7a7f;
 }
 
 /*-------------------------
  网格布局
 --------------------------*/
 
 ul.grid{
  list-style: none;
  width: 100vw;
  margin: 0 auto;
  text-align: left;
 }
 
 ul.grid li{
  width:50vw;
  height:50vw;
  padding: 1px;
  float:left;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
 }
 
 ul.grid li img{
  width:100%;
  height:100%;
  object-fit: cover;
  display:block;
  border:none;
  /* padding: 10px; */
  box-sizing: border-box;
 }
 </style>
