<template>
    <div class="dpAudio">
      <audio :src="weburl + audio_url" ref="audio"
             @loadedmetadata="loadedmetadataFunc"
             @timeupdate="timeupdateFunc"
             @ended="audioEndedFunc"
      ></audio>
        <!--倍数显示-->
      <div class="timesTheSpeedPlayContainer" v-if="openSpeed">
        <span v-for="(multiple,index) in multipleArr" :key="index" :class="{ multiple:true,currentMultipleClass:multiple === currentMultiple }" @click="setCurrentMultiple(multiple)">{{multiple}}X</span>
      </div>
        <!--音量控制-->
      <!--<div class="changeVolumeContainer" v-if="openVolume">
        <img src="../assets/images/dpAudio/volumeIcon.png">
        <Slider v-model="volume" class="volumeSlider"></Slider>
        <span>{{volume}}%</span>
      </div>-->
      <div class="itemAudioListContainer" v-if="openAudio">
        <div class="itemAudioListItem" v-for="(n,i) in audioArr" :key="i" @click="changeAudio(n,i)">
          <div class="itemAudioListItemName">{{ n.name }}</div>
          <div class="bugle">
            <img :src="trumpet_index === i ? require('../assets/images/dpAudio/bugle.png'):require('../assets/images/dpAudio/volumeIcon.png')">
          </div>
        </div>
      </div>
      <!--播放器控制面板-->
      <div class="audioCorePanel">
        <div class="playBtnContainer">
          <!-- <img src="../assets/images/dpAudio/listBtn.png" class="listBtn" @click="openAudioListFunc()"> -->
          <img :src="playBtnIcon" class="playOrPauseBtn" @click="playOrPauseFunc()">
        </div>
        <div class="audioCorePanelRight" ref="audioCorePanelRight">
          <div class="audioCorePanelTopContainer">
              <div><van-notice-bar :text="audio_name" /></div>
              <div  @click="openAudioListFunc()"><img :src="audioListImg" alt=""></div>
              <div @click="openSpeedFunc()">
                <img src="../assets/images/dpAudio/speed.png" alt="" v-if="isSpeed">
                <span v-else>{{speed_val}}X</span>
              </div>
           <!-- <div @click="openSpeedFunc()">倍速{{currentMultiple}}X</div>
             <div @click="openVolumeFunc()">音量</div>-->
          </div>
          <!-- 因为播放进度条比较特殊复杂，所以自己手动撸了一个slider，由于时间原因，在这里就不封装成组件了，在demo.vue中有清洁版的例子，可移步观看 -->
          <div class="lkSlider" ref="lkSlider" @touchmove="touchmoveFunc($event)" @touchend="touchendFunc()">
            <div class="wrapper" ref="wrapper" @click="clickWrapperFunc($event)">
              <div class="fill" ref="fill"></div>
              <div class="sliderBar" ref="sliderBar" @touchstart="touchstartFunc()"></div>
            </div>
          </div>
          <!-- 以上slider因为当时本人水平有限，不完美之处，尽情谅解 -->
          <span class="currentTimePoint">{{currentTimePoint}}</span>
          <span class="totalTimePoint">{{totalTimePoint}}</span>
        </div>
      </div>
    </div>
</template>

<script>
import { setTimeout } from 'timers';
import audioListImg from "../assets/images/dpAudio/audiolist.png";
import openImg from "../assets/images/dpAudio/up.png";
import wx from "weixin-jsapi"
export default {
    name: 'dpAudio',
    props:["audioArr","itemIndex","pauseStatus"],//itemIndex是为了区分播放当前的按钮的索引，以作区分
    inject:['stopPropagation'],
    data () {
      return {
        paused:true,                                              //播放器的播放状态，默认是不播放的
        multipleArr:["0.5","1.0","1.25","1.5","2.0"],             //播放倍数的数组
        openSpeed:false,                                          //开启“倍速”面板，默认关闭
        openVolume:false,                                         //开启“声音”面板，默认关闭
        openAudio:false,                                          //开启“音频列表”面板，默认关闭
        volume:this.$store.state.volume,                          //当前播放器的音量
        totalTime:"",                                             //本条音频的总时间，单位是秒，Number型
        totalTimePoint:"",                                        //本条音频的总时间点，格式为"xx:xx"，String型
        currentTime:0,                                            //本条音频当前播放到的时间，单位是秒，Number型
        currentTimePoint:"00:00",                                 //本条音频当前播放到的时间点，格式为"xx:xx"，String型
        drag:0,                                                   //是否允许拖拽Slider滑块，0：不允许；1：允许
        audio_name:'',
        audio_url :'',
        trumpet_index:0,
        audioListImg,
        isSpeed: true,
        speed_val: ""
      }
    },
    created(){
        this.audio_name  = this.audioArr[0].name;
        this.audio_url = this.audioArr[0].url;
        // this.paused = this.pauseStatus;
    },
    methods: {
        // 音频播放完毕的方式
        audioEndedFunc () {
            this.paused = true ;
            this.$refs.audio.pause();
            const num = parseInt(this.trumpet_index)+ 1;
            if(num < this.audioArr.length){
                this.changeAudio(this.audioArr[num],num);
                this.paused =false;
            }
            let data ={};
            data= {
                paused:this.paused,
                key:this.itemIndex
            };
            this.$emit('getPause',data);
        },
        changeAudio(n,i){
            this.audio_name = n.name;
            this.audio_url = n.url;
            this.trumpet_index = i;
            this.$refs.sliderBar.style.left = "0px";
            this.$refs.fill.style.width = "0px";
            this.paused = false;
            setTimeout(()=>{
                this.$refs.audio.play();
            },10)
        },

        // 暂停/播放
        playOrPauseFunc () {
             if(this.$refs.audio.paused){
               // this.$refs.audio.volume = this.volume/100
                 this.$refs.audio.play();
                 this.paused = false;
             }else{
               this.$refs.audio.pause();
               this.paused = true;
             }
            // this.paused = !this.paused;
            let data ={};
            data= {
                paused:this.paused,
                key:this.itemIndex
            };
            this.$emit('getPause',data)
        },
        // 打开“加速”面板
        openSpeedFunc () {
            if(this.openVolume || this.openAudio){
                this.openVolume = false;
                this.openAudio = false;
            }
            this.openSpeed = !this.openSpeed;
            if(!this.speed_val){
                this.isSpeed = false;
                window.addEventListener('beforeunload',function () {
                    localStorage.setItem('speed',this.isSpeed);
                });
           this.isSpeed= JSON.stringify(localStorage.getItem('speed'));
           }
        },
        // 打开“声音”面板
        openVolumeFunc () {
            if(this.openSpeed || this.openAudio){
                this.openSpeed = false;
                this.openAudio = false;
            }
            this.openVolume = !this.openVolume;
        },
        // 打开音频播放列表的方法
        openAudioListFunc () {
            if(this.openVolume || this.openSpeed){
                this.openVolume = false;
                this.openSpeed = false;
            }
            if(this.openAudio){
                this.audioListImg = audioListImg;
                this.openAudio = false;
            }else {
                this.audioListImg = openImg ;
                this.openAudio = true;
            }
        },
        // 设置当前发播放倍数
        setCurrentMultiple (multiple) {
            this.$store.commit("set_currentMultiple", multiple);
            this.$refs.audio.playbackRate = parseFloat(multiple);
            this.speed_val = multiple;
            this.openSpeed = false;
            this.isSpeed = false;
            // console.log(this.speed_val)
        },
        // 音频加载完毕时所触发的方法
        loadedmetadataFunc () {
            this.totalTime = this.$refs.audio.duration;
            this.totalTimePoint = this.transTime(this.$refs.audio.duration);
        },
        // 转换音频时长显示,目的是将秒转换为几分几秒的格式
        transTime (time) {
            var duration = parseInt(time);
            var minute = parseInt(duration/60);
            var sec = duration%60+'';
            var isM0 = ':';
            if(minute === 0){
                minute = '00';
            }else if(minute < 10 ){
                minute = '0'+minute;
            }
            if(sec.length === 1){
                sec = '0'+sec;
            }
            return minute+isM0+sec
        },
        // timeupdate 事件是在播放位置改变时触发的方法
        timeupdateFunc () {
                setTimeout(()=> {
                    this.currentTime = this.$refs.audio.currentTime;
                    this.currentTimePoint = this.transTime(this.$refs.audio.currentTime);
                    let ratio = Math.floor(this.$refs.audio.currentTime) / Math.floor(this.$refs.audio.duration);
                    let progress = this.$refs.wrapper.offsetWidth * ratio;
                    if (this.drag === 1) {
                        return false;
                    }
                    this.$refs.sliderBar.style.left = progress + "px";
                    this.$refs.fill.style.width = progress + "px";
                },10)
        },
        // ==================slider方法==================
        touchstartFunc () {
            this.drag = 1;
            this.stopPropagation();
        },
        touchmoveFunc (e) {
            if(this.drag === 1){
                let maxLength = this.$refs.audioCorePanelRight.offsetLeft + this.$refs.wrapper.offsetLeft + this.$refs.wrapper.offsetWidth - this.$refs.sliderBar.offsetWidth;
                let minLength = this.$refs.audioCorePanelRight.offsetLeft + this.$refs.wrapper.offsetLeft;
                if (e.touches[0].pageX > maxLength) {
                    this.$refs.sliderBar.style.left = this.$refs.wrapper.offsetWidth - this.$refs.sliderBar.offsetWidth + "px";
                    this.$refs.fill.style.width = this.$refs.wrapper.offsetWidth + "px";
                } else if (e.touches[0].pageX < minLength) {
                    this.$refs.sliderBar.style.left = "0px";
                    this.$refs.fill.style.width = "0px";
                }else{
                    this.$refs.sliderBar.style.left = (e.touches[0].pageX - this.$refs.audioCorePanelRight.offsetLeft - this.$refs.wrapper.offsetLeft) + "px";
                    this.$refs.fill.style.width = (e.touches[0].pageX - this.$refs.audioCorePanelRight.offsetLeft - this.$refs.wrapper.offsetLeft) + "px";
                }
            }
        },
        touchendFunc () {
            this.jumpTimePointFunc();
            this.drag = 0;
        },
        clickWrapperFunc (e) {
            if (e.target !== this.$refs.sliderBar) {
                if (e.offsetX > (this.$refs.wrapper.offsetWidth - this.$refs.sliderBar.offsetWidth)) {
                    this.$refs.sliderBar.style.left = this.$refs.wrapper.offsetWidth - this.$refs.sliderBar.offsetWidth +"px";
                    this.$refs.fill.style.width = this.$refs.wrapper.offsetWidth - this.$refs.sliderBar.offsetWidth + "px";
                } else {
                    this.$refs.sliderBar.style.left = e.offsetX + "px";
                    this.$refs.fill.style.width = e.offsetX + "px";
                }
            }
            this.jumpTimePointFunc();
        },
        jumpTimePointFunc () {
            let arr = this.$refs.fill.style.width.split("p");
            let fillWidth = parseFloat(arr[0]);
            let ratio = fillWidth / this.$refs.wrapper.offsetWidth;
            let jumpTime;
            jumpTime = this.$refs.audio.duration * ratio;
            this.$refs.audio.currentTime = jumpTime;
        }
    },
    computed:{
      playBtnIcon () {
        return require(this.paused ? "../assets/images/dpAudio/pauseBtn.png" : "../assets/images/dpAudio/playBtn.png");
      },
      currentMultiple () {
        return this.$store.state.currentMultiple;
      },
      weburl () {
          return this.$store.state.weburl;
      },
      srcurl () {
          return this.$store.state.srcurl;
      }

    },
    watch: {
      volume (n) {
        this.$store.commit("set_volume", n);
        this.$refs.audio.volume = parseInt(n)/100;
      },
        pauseStatus(val){
          this.paused = val;
          console.log(val);
          if(this.paused){
              this.$refs.audio.pause();
          }else{
              this.$refs.audio.play();
          }
        }

    },
    mounted () {
        setTimeout(() => {
          let weixp = true;
          if(this.$refs.audio.pause){
            // this.$refs.audio.play();
            let _this = this;
            wx.config({
              // 配置信息, 即使不正确也能使用 wx.ready
              debug: false,
              appId: "",
              timestamp: 1,
              nonceStr: "",
              signature: "",
              jsApiList: []
            });
            wx.ready(function() {
              // console.log("11111111111");
              _this.$refs.audio.play();
              _this.paused = false;
              weixp = false;
            });
          }
          // console.log(weixp);
          if(weixp){
            this.paused = true;
          }
        },500)
        // this.$nextTick(()=>{
        //     // console.dir(this.$refs.audio)
        //     if(!this.paused){this.$refs.audio.play();}

        // })
    },
    unmounted () {
      // this.$refs.audio.pause();
    },
    beforeUnmount () {
      this.$refs.audio.pause();
    }

}
</script>

<style scoped  lang="less">
  @import "../assets/less/common";
.dpAudio{
    width: 100%;
    position: fixed;
    bottom: 0;
    left:0;
    z-index: 102;
}
.audioCorePanel {
  width: 100%;
  height: 1.37rem;
  display: flex;
    background-color:@light-color;
  /*background:linear-gradient(0deg,rgba(254,233,255,1) 0%,rgba(205,236,255,1) 100%);*/
}
.playBtnContainer {
  width: 18%;
  height: 1.37rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
/* .playBtnContainer img {
  width: 1.2rem;
  height: 1.2rem;
} */
.listBtn{
  position: absolute;
  top: 0.05rem;
  left: 0;
  width: 0.35rem;
}
.playOrPauseBtn{
  width: 1.2rem;
  height: 1.2rem;
}
.timesTheSpeedPlayContainer {
  font-size: 0.22rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
  .timesTheSpeedPlayContainer, .changeVolumeContainer{
      width: 100%;
      height: 0.88rem;
      background: @speed-bg;
  }
.changeVolumeContainer{

  position: relative;
}
.multiple{
  margin-left: 0.22rem;
  margin-right: 0.22rem;
  letter-spacing: 1px;
  color: @white;
}
.currentMultipleClass{
  color: @speed-color;
  border-bottom: 1px solid @speed-color;
}
.audioCorePanelRight{
  width: 82%;
  height: 1.37rem;
  position: relative;
   & > span {
     position: absolute;
     color: @white;
     font-size: 0.16rem;
     top: 75%;
     &.totalTimePoint{
       left: 85%;
     }
   }
}
.van-notice-bar{
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 1px; 
    color: #fff; 
    font-size: 0.22rem;
    line-height: 24px;
    background-color: #ffffff00;
}


.audioCorePanelTopContainer{
  width: 100%;
  height: 0.63rem;
  display: flex;
  align-items: center;
   & > div {
       font-size: 0.22rem;
       color: @white;
        &:nth-child(1){
            width: 70%;
            height: 0.63rem;
            line-height: 0.63rem;
            letter-spacing: 0.01rem;
            margin-right: 0.1rem;
        }
        &:nth-child(3) , &:nth-child(2){
            height: 0.46rem;
            width: 0.88rem;
            margin-right: 0.2rem;
        }
       img {
           width: 100%;
       }
    }

}


.volumeSlider{
  position: absolute;
  left:13%;
  top: 10%;
  width: 74%;
}
.changeVolumeContainer img{
  position: absolute;
  width: 0.31rem;
  height: 0.25rem;
  left: 7%;
  top: 37%;
}
.changeVolumeContainer span{
  color: #FFFFFF;
  position: absolute;
  right: 4%;
  top: 32%;
}

.itemAudioListContainer{
  // width: 100%;
  padding-left: 0.44rem;
  padding-right: 0.44rem;
  background-color: rgba(54, 54, 54, 0.9);
  & > div {
    width: 100%;
    height: 0.88rem;
    border-bottom: 0.01rem solid #b4b4b4;
    display: flex;
    &:last-child {
      border-bottom: none;
    }
  }
}
/*.itemAudioListItem{

}*/
.itemAudioListItemName{
  width: 90%;
  height: 0.88rem;
  color: white;
  font-size: 0.22rem;
  line-height: 0.88rem;
}
.bugle{
  width: 10%;
  position: relative;
  text-align: center;
  line-height: 0.5rem;
}
.bugle img{
  width: 0.4rem;
}

/* 自创slider样式 */

.lkSlider{
  width: 6rem;
  height: 0.3rem;
  position: relative;
    .wrapper{
        width: 5.4rem;
        height: 4px;
        background-color: @progress-color;
        position: absolute;
        top: 0.11rem;
        left: 0.1rem;
        border-radius: 0.05rem;
    }
    .fill{
        width: 0;
        height: 4px;
        background: @progress-active-color;
        border-radius: 0.05rem 0 0 0.05rem;
    }
    .sliderBar{
         width: 0.18rem;
         height: 0.18rem;
         background:@btn-gradient-color;
         .border-radius(0.07rem);
         position: absolute;
         top: -0.04rem;
     }
}


</style>

