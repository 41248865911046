<template>
    <div class="message">
        <div class="msgItem">
            <div class="leftContainer">
                <p class="msgTitle">节日祝贺</p>
                <p class="msgContent">节日祝贺详情文字</p>
            </div>
            <div class="rightContainer">
                <p class="msgDate">2019-03-03 10:27</p>
                <span class="lookDetailBtn" @click="goDetail()">查看详情</span>
            </div>
        </div>

        <div class="msgItem">
            <div class="leftContainer">
                <p class="msgTitle">节日祝贺</p>
                <p class="msgContent">节日祝贺详情文字</p>
            </div>
            <div class="rightContainer">
                <p class="msgDate">2019-03-03 10:27</p>
                <span class="lookDetailBtn" @click="goDetail()">查看详情</span>
            </div>
        </div>

        <div class="msgItem">
            <div class="leftContainer">
                <p class="msgTitle">节日祝贺</p>
                <p class="msgContent">节日祝贺详情文字</p>
            </div>
            <div class="rightContainer">
                <p class="msgDate">2019-03-03 10:27</p>
                <span class="lookDetailBtn" @click="goDetail()">查看详情</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "message",
    data: function () {
        return {

        }
    },
    methods: {
        goDetail: function () {
            this.$router.push({path: `/msgDetail`});
        }
    }
}
</script>

<style scoped lang="less">
    @import "../assets/less/common";
.message{
    background-color: #f6f6f6;
    height: 50vh;
    padding-top: 0.4rem;
}
.msgItem{
    width: 7.03rem;
    height: 1.34rem;
    background-color: #ffffff;
    margin: 0 auto 0.4rem;
    border-radius:0.23rem;
    display: flex;
}
.leftContainer{
    width: 68%;
    position: relative;
}
.rightContainer{
    width: 32%;
    position: relative;
}
.msgTitle{
    font-size: 0.3rem;
    color: #4C4C4C;
    margin-top: 0.25rem;
    padding-left: 0.25rem;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
}
.msgContent{
    font-size: 0.24rem;
    color: #9D9D9D;
    margin-top: 0.05rem;
    padding-left: 0.25rem;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
}
.msgDate{
    font-size: 0.24rem;
    color: #9D9D9D;
    letter-spacing: 0.01rem;
    margin-top: 0.25rem;
}
.lookDetailBtn{
    font-size: 0.24rem;
    color: #4795F6;
    position: absolute;
    right: 0.2rem;
    bottom: 0.2rem;
}
</style>
