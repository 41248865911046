<template>
    <div class="Header">

        <!-- <div class="nameContainer">丁佑君烈士陵园陈列馆</div>
        <div class="nameContainer">{{$t("test")}}</div>
        <div class="langAndMsgContainer">
            <img src="../assets/images/header/langBtn.png" class="langBtn" @click="langBtnFunc()">
            <img src="../assets/images/header/msgBtn.png" class="msgBtn" @click="goMsg()">
        </div> -->

        <transition name="van-fade">
            <form action="/" v-show="bar == 'searchbar'">
                <van-search v-model="keyword" show-action :action-text="$t('cancel')" :placeholder="$t('placeholder')" @input="searchItem()" @search="onSearch" @cancel="onCancel" />
            </form>
        </transition>

        <!-- <transition name="van-slide-up" > -->
            <!-- <div class="language" v-show="bar == 'lang'">
                lang
            </div> -->
        <!-- </transition> -->
        <transition name="van-fade">
            <div class="bar" v-show="bar == 'icon'">
                <van-icon v-if="layout == 'list'" class-prefix="iconfont icon-liebiao" color="#fff" @click="changelayout()"/>
                <van-icon v-else  class-prefix="iconfont icon-four-squares" color="#fff" @click="changelayout()"/>
                <van-icon class-prefix="iconfont icon-saoma2" color="#fff" @click="scanQRCode()"/>
                <van-icon class-prefix="iconfont icon-sousuo" color="#fff" @click="showSearch()"/>
                <van-icon class-prefix="iconfont icon-huaban-" color="#fff" @click="langBtnFunc()"/>
            </div>
        </transition>





    </div>
</template>

<script>
import wx from 'weixin-js-sdk';
import { Toast } from 'vant';
import '../assets/icon/icon.css';
export default {
    name: "Header",
    data: function () {
        return {
            bar: 'icon',
            keyword: ''
        }
    },
    computed: {
        showChooseLang: function () {
            return this.$store.state.showChooseLang;
        },
        layout: function () {
            return this.$store.state.layout;
        }
    },
    mounted() {
        // 所有需要使用JS-SDK的页面必须先注入配置信息，否则将无法调用
        let uri = location.href.split('#')[0];
        this.$http({
            method: "post",
            url: '/smartguide/wechat/get_config',
            // url: window.location.protocol + '//www.indoorlink.com/smartguide/wechat/get_config',
            dataType: "json",
            data: {
                uri: uri,
                catg: 42
            }
        })
        .then(res => {
            // console.log(res.data);
            if (res.data.ret === 0) {
                wx.config({
                    debug: false,
                    appId: res.data.datas.appid, // 必填，公众号的唯一标识
                    timestamp: res.data.datas.timestamp, // 必填，生成签名的时间戳，精确到秒
                    nonceStr: res.data.datas.noncestr, // 必填，生成签名的随机串
                    signature: res.data.datas.signature, // 必填，签名
                    jsApiList: [
                        'openLocation',
                        'scanQRCode'
                        ]
                })
            }else{
                console.log(res);
            }
        })
        .catch(err => {
            console.log("签名请求失败"+err);
        })
    },
    methods: {
        changelayout(){
            if(this.layout == 'grid'){
                this.$store.commit("set_layout",'list');
            }else{
                this.$store.commit("set_layout",'grid');
            }
        },
        scanQRCode: function() {
            var that = this;
            var ua = navigator.userAgent.toLowerCase();
            if(ua.match(/MicroMessenger/i)=="micromessenger") {
                wx.scanQRCode({
                    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
                    success: function (res) {
                        // var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                        // console.log(result);
                        let goods_sn = new RegExp("goods_sn=[0-9]*","g").exec(res.resultStr);
                        var sn_query = {};
                        if(goods_sn != null){
                            sn_query.goods_sn = goods_sn[0].replace('goods_sn=','');
                        }else{
                            var sn = new RegExp("sn=[0-9]*","g").exec(res.resultStr);
                            sn_query.sn = sn[0].replace('sn=','');
                        }

                        if(sn_query){
                            Toast.loading({
                                message: '识别成功',
                                forbidClick: true,
                                duration: 800
                            });
                            that.$router.push({path:"/itemDetail",query:sn_query});
                        }
                    },
                    fail: function (err) {
                        console.info(err.errMsg);
                    }
                });
            } else {
                this.$router.push({path:"/scanQRCode"});
            }


        },
        showSearch(){
            this.bar = 'searchbar';
            this.$emit('tabbar','searchbar');
            // this.$router.push({path:"/Search"});
        },
        onSearch(){
            console.log(this.keyword);
        },
        onCancel(){
            this.bar = 'icon';
            this.$emit('tabbar','index');
        },
        searchItem() {
            console.log(this.keyword);
            this.$http({
                method:'post',
                data:{
                    area: this.$area,
                    lang: this.$language,
                    keyword: this.keyword
                },
                url: '/qrcode/public/index.php/index/items/searchItem',
                dataType: 'json'
            }).then(res =>{
                if(res.data.status === 1){
                    this.$emit('searchItem',res.data);
                    console.log(res.data);
                }else {
                    this.$Message.error({
                        top: 100,
                        content: res.data.msg,
                    });
                }
            }).catch(()=>{
                
            })
        },        
        langBtnFunc: function () {
            if(this.showChooseLang){
                this.$store.commit("set_showChooseLang",false);
            }else{
                this.$store.commit("set_showChooseLang",true);
            }
        },
        goMsg: function () {
            this.$router.push({path: `/message`});
        }
    },
    watch: {

    },    
}
</script>

<style scoped lang="less">
@import "../assets/less/common";
.Header{
    width: 100vw;
    height: 1.04rem;
    background: @primary-color;
    top: 0;
    left: 0;
    z-index: 99;

    .bar{
        height: 1.04rem;
        padding: 0 0.96rem;
        display: flex;
        /* flex-wrap: nowrap; */
        justify-content: space-between;
        /* position: absolute; */
        .iconfont{
            font-size: 0.45rem;
            top: 0.33rem;
            width: 0.5rem;
            height: 0.5rem;
        }
    }

    // .bar{
    //     // border-bottom: 1px solid #A0A0A0;
    //     position: relative;
    //     height: 1.04rem;
    //     padding: 0 0.96rem;
    //     --child: 4;
    //     --clild_index: calc(var(--child) - 1);
    //     .iconfont{
    //         position: absolute;
    //         font-size: 0.45rem;
    //         top: 0.33rem;
    //     }
    //     .iconfont:nth-child(2) {
    //         margin-left: calc(((100% - 2.37rem)/var(--clild_index))*1);
    //     }
    //     .iconfont:nth-child(3){
    //         margin-left: calc(((100% - 2.37rem)/var(--clild_index))*2);
    //     }
    //     .iconfont:nth-child(4){
    //         margin-left: calc(((100% - 2.37rem)/var(--clild_index))*3);
    //     }
    // }
    form{
        display: flex;
        width: 100vw;
        height: 1.04rem;
        .van-search{
            width: 100vw;
        }
    }
}

.returnBtnContainer{
    width: 1.5rem;
    flex-grow:1;
    position: relative;
    line-height: 1rem;
}
.returnBtnContainer img{
    width: 0.22rem;
    height: 0.41rem;
    margin-left: 0.3rem;
}
.nameContainer{
    width: 4.5rem;
    flex-grow:2;
    text-align: center;
    line-height: 0.8rem;
    font-size: 0.36rem;
    color: #FFFFFF;
}
.langAndMsgContainer{
    width: 1.5rem;
    flex-grow:1;
    line-height: 1.1rem;
}
.langBtn{
    width: 0.48rem;
}
.msgBtn{
    width: 0.48rem;
    margin-left: 0.22rem;
}
</style>
