<template>
  <!-- <div class="home">
    <div class="floorItem" v-for="(item , t) in floorLists" :key="t">
      <h3 v-if=" floorLists.length > 1"> {{ item.name }}</h3>
      <div class="hallItemContainer">
        <div class="hallItem"  v-for="(n , i) in item.child" @click="linkToHallDetail(n.id)" :key="i">
          <div><img :src=" weburl + n.image"></div>
          <p>{{ n.name }}</p>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div class="nameContainer">{{$t("test")}}</div> -->
  <div class="Search">
    <Header @tabbar='tabbarFun' @searchItem='searchItem'></Header>
    
    <div class="floor" v-for="(floor,f) in floorLists" :key="f">
      
      <h5> {{floor.name}}</h5>
      
      <transition name="van-fade">
      <div class="hall" v-show="layout == 'list'">
        <ul class="list" v-for="(hall,h) in floor.child" :key="h">
          <h6> {{hall.name}}</h6>
          <!-- 这种布局显示小图片和标题 内容 -->
          <li v-for="(item,i) in hall.items" :key="i">
            <div class="listcard" @click="linkToItemDetail(item.item_id)">
              <van-image fit="cover" :src="weburl+item.thumb"/>
              <div class="cardinfo">
                <div class="title van-ellipsis">{{item.name}}</div>
                <div class="content van-multi-ellipsis--l3">{{item.intro}}</div>
                <div class="preview">浏览量 {{item.browse}} 次</div>
                <div class="playOrPauseContainer" @click="playAudio(f+h+i)" >
                  <img :src="current_idx === f+h+i? audio_btn_img: require('../assets/images/home/pauseBtn.png')">
                </div>
                <dpAudio v-if="currentPlayStatus && layout == 'list' && current_idx === f+h+i" :audioArr ="item.audio" :itemIndex="f+h+i" @getPause ='setPause' :pauseStatus="paused" v-on:click.stop="doThis()"></dpAudio>
              </div>
            </div>
          </li>
        </ul>
      </div>
      </transition>
      <transition name="van-fade">
      <div class="hall" v-show="layout == 'grid'">
        <ul class="grid" v-for="(hall,h) in floor.child" :key="h">
          <h6> {{hall.name}}</h6>
          <!-- 这种布局显示小图片和标题 -->
          <li v-for="(item,i) in hall.items" :key="i">
            <div class="gridcard" @click="linkToItemDetail(item.item_id)">
              <van-image fit="cover" :src="weburl+item.thumb"/>
              <div class="cardinfo">
                <div class="title van-ellipsis">{{item.name}}</div>
                <!-- <div class="content">内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容</div> -->
                <div class="preview">浏览量 {{item.browse}} 次</div>
                <div class="playOrPauseContainer" @click="playAudio(f+h+i)" >
                  <img :src="current_idx === f+h+i? audio_btn_img: require('../assets/images/home/pauseBtn.png')">
                </div>
                <dpAudio v-if="currentPlayStatus && layout == 'grid' && current_idx === f+h+i" :audioArr ="item.audio" :itemIndex="f+h+i" @getPause ='setPause' :pauseStatus="paused" v-on:click.stop="doThis()"></dpAudio>
              </div>
            </div>
          </li>
          
        </ul>
        
      </div>
      </transition>

    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import Header from '../components/Header.vue'
import dpAudio from '../components/dpAudio.vue'
import playImg from '../assets/images/home/playBtn.png';
import pauseImg from '../assets/images/home/pauseBtn.png'
export default {
  name: 'Search',
  components: {
    Header,
    dpAudio
  },
  inject:['stopPropagation'],
  data: function () {
    return {
      //布局
      column: 1,
      imgLists:[],
      floorLists:[],
      //功能切换
      tabbar: 'index',
      //音乐播放器
      currentPlayStatus:false,                //播放器显示
      audio_btn_img : pauseImg,
      current_idx: 0,
      paused:true //播放器按钮状态

    }
  },
  created: function () {
    // this.getHomeList();
  },
  methods: {
    linkToItemDetail (id) {
        this.stopPropagation();
        this.$router.push({path:"/itemDetail",query:{item_id:id}});
    },
    getHomeList(){
      this.$http({
        method:'post',
        data:{
          area: this.$area,
          lang: this.$language,
        },
        url: '/qrcode/public/index.php/index/Items/getCategory',
        dataType: 'json'
      }).then(res =>{
        if(res.data.status === 1){
          this.floorLists = res.data.result;
          // console.log(this.floorLists);
        }else {
          Toast.fail('res.data.msg');
        }
      }).catch(()=>{
        Toast.fail('请联系工作人员');
      })
    },
    setPause(value){
      //true 表示 不播放，false表示播放，因为这里记录的是子组件中的pause状态。
      this.current_idx = value.key;
      this.audio_btn_img = value.paused ? pauseImg:playImg;
    },
    playAudio(i){
      this.stopPropagation();
      if(this.current_idx !== i){
        this.currentPlayStatus = false;
        this.paused = true;
      }
      setTimeout(()=>{
        this.current_idx = i;
        this.currentPlayStatus = true;
        this.paused = !this.paused ;
        this.audio_btn_img = this.paused ? pauseImg : playImg;
      },10);//可以解决音频currentTime未定义
    },
    tabbarFun(bar){
      // this.tabbar = bar;
      if(bar == 'index'){
        this.getHomeList();
      }
    },
    searchItem(item){
      console.log(item);
      this.floorLists = [];
      this.floorLists[0] = {};
      this.floorLists[0]['name'] =  "搜索结果："
      this.floorLists[0]['child'] = item.result;
      console.log(this.floorLists);

    },
    doThis(){
      return false;
    }

  },
  computed: {
    weburl: function () {
      return this.$store.state.weburl;
    },
    srcurl: function () {
        return this.$store.state.srcurl;
    },
    layout: function () {
        return this.$store.state.layout;
        // return 'grid';
    }

  }
}
</script>

<style scoped lang="less">
  @import "../assets/less/common";
  .home{
    height: 100vh;
    background-color: #f3f3f3;
  }
  .floor{
    position: relative;
    width: calc(100% - 0.5rem);
    left: 0.25rem;
    top: 0.18rem;
    // border-bottom: 1px solid #5c5c5c;
    margin-bottom: 0.5rem;
    h5{
      margin: 0.1rem auto;
      font-size: 0.3rem;
      font-weight: 500;
    }
    .hall{
      position: relative;
      border-top: 1px solid #a0a0a0;
      width: 100%;
    }
  }
  
  .list{
    padding-bottom: 0.1rem;
    h6{
      margin: 0.06rem auto 0.04rem;
      font-size: 0.24rem;
      letter-spacing: 0.05rem;
      display: block;
      color: #701D19;
      font-weight: 500;
    }
    .listcard{
      position: relative;
      width:100%;
      height: 2.3rem;
      margin: 0.16rem auto 0.2rem;

      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 1px;
      background: #fff;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

      .van-image{
        width:42%;
        height: 100%;
      }
      .cardinfo{
        position: relative;
        width: 51vw;
        float: right;
        // font-size: 1.1rem;
        // height: 100%;
        margin-top: 0.1rem;
        .title{
          font-size: 0.3rem;
          font-weight: 500;
          margin-top: 1vw;
          letter-spacing: 0.05rem;
          color: #0C0C0C;
        }
        .content{
          height: 0.9rem;
          position: relative;
          margin-top: 1vw;
          padding-right: 5vw;
          font-size: 0.24rem;
          line-height: 0.3rem;
          letter-spacing: 0.01rem;
          color: #4b4b4b;

          // 超出省略
          // display: -webkit-box;
          // -webkit-box-orient: vertical;
          // -webkit-line-clamp: 3;
          // overflow: hidden;
        }
        .preview{
          position: absolute;
          font-size: 0.2rem;
          margin-top: 0.2rem;
          // bottom: 1vw;
          color: rgb(100, 100, 100);
        }
        .playOrPauseContainer{
          left: 2.7rem;
          top: -0.03rem;
        }
      }
    }
  }

  .grid{
    border-bottom: 1px solid #a0a0a0;
    padding: 0 0 0.15rem 0;
    h6{
      margin: 0.06rem auto 0.04rem;
      font-size: 0.24rem;
      font-weight: 500;
      letter-spacing: 0.05rem;
      display: block;
      color: #701D19;
    }
    li:nth-child(even){
      margin-right: 0.3rem;
    }
    li:nth-child(even):last-child{
      margin-right: (50vw - 0.25rem);
    }
    li{
      width:calc(50vw - 0.4rem);
      height:calc(50vw - 0.4rem);
      float: left;
      box-sizing: border-box;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      margin: 0.1rem auto;

      .gridcard{
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 1px;
        // background: #fff;
        .van-image{
          width:100%;
          height: calc((50vw * 0.66) - 0.4rem);
        }
        .cardinfo{
          position: relative;
          width: calc(50vw - 0.5rem);
          float: right;
          height: 100%;
          .title{
            // margin-top: 0.15rem;
            font-size: 0.24rem;
            letter-spacing: 0.01rem;
            // 超出省略
            // display: -webkit-box;
            // -webkit-box-orient: vertical;
            // -webkit-line-clamp: 1;
            // overflow: hidden;
          }
          .preview{
            position: absolute;
            font-size: 0.2rem;
            margin-top: 0.26rem;
            // bottom: 1vw;
            color: rgb(100, 100, 100);
          }
        }
        .playOrPauseContainer{
          left: 2.4rem;
          top: -0.06rem;
        }
      }
    }
  }

  .grid::after {
    content: '';
    /*建议加个height:0*/
    height: 0;
    display: block;
    clear: both;
    visibility: hidden;
  }

  .playOrPauseContainer{
    width: 0.77rem;
    height: 0.77rem;
    position: relative;
      img{
          width: 0.64rem;
          padding: 0.065rem;
          position: absolute;
          // margin: 0.175rem auto;
      }
  }


</style>

