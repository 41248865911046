<template>
    <div class="itemDetail">
        <router-link to="/"><van-icon name="wap-home-o" size="0.8rem" color="#fff" /></router-link>
        <div class="picSwipeContainer" v-if="item_info.image !== undefined && item_info.image.length > 0">
            <van-image class="titleimge" radius="1" fit="cover" :src="titleimge"/>
            <div class="picSwipeBox">
                <div class="controller">
                    <van-icon name="arrow-left" class="prev" @click="prev" color="#fff" size="0.4rem"/>
                    <van-icon name="arrow" class="next" @click="next" color="#fff" size="0.4rem"/> 
                </div>

                <template v-if="item_info.image.length >= 4">
                <swiper
                    class="picSwipe"
                    :slides-per-view="4"
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                >
                    <!-- <template v-if="item_info.image.length >= 4"> -->
                    <swiper-slide  v-for="(imageItem,index) in item_info.image" :key="index">
                        <van-image class="thumbimg" fit="cover" radius="4" :src="weburl + imageItem" @click="changeTitleimg(index);"/>
                    </swiper-slide>

                    <!-- </template> -->
                    <!-- <template v-else>
                    <swiper-slide>
                        <van-image class="thumbimg" fit="cover" radius="4" :src="weburl + item_info.image[0]" @click="changeTitleimg(0);"/>
                    </swiper-slide>
                    <swiper-slide>
                        <van-image class="thumbimg" fit="cover" radius="4" :src="weburl + item_info.image[1]" @click="changeTitleimg(1);"/>
                    </swiper-slide>
                    <swiper-slide>
                        <van-image class="thumbimg" fit="cover" radius="4" :src="weburl + item_info.image[2]" @click="changeTitleimg(2);"/>
                    </swiper-slide>
                    <swiper-slide>
                        <van-image class="thumbimg" fit="cover" radius="4" :src="weburl + item_info.image[3]" @click="changeTitleimg(3);"/>
                    </swiper-slide>
                    </template> -->
                </swiper>
                </template>
                <template v-else>
                    <swiper
                        class="picSwipe1"
                        :slides-per-view="4"
                        @swiper="onSwiper"
                        @slideChange="onSlideChange"
                    >

                        <swiper-slide  v-for="(imageItem,index) in item_info.image" :key="index">
                            <van-image class="thumbimg" fit="cover" radius="4" :src="weburl + imageItem" @click="changeTitleimg(index);"/>
                        </swiper-slide>
                    </swiper>
                </template>

            </div>
        </div>
        <div class="itemDetailContent">
            <p>基本信息</p>
            <div style="height:2px;background-color:#D1C0A5;"></div>
                <div class="content" v-html="item_info.information"></div>
            <p>藏品介绍</p>
            <div style="height:2px;background-color:#D1C0A5;"></div>
                <div class="content" v-html="item_info.intro"></div>
        </div>
        <!-- <div class="itemDetailContent">
            <h3>{{item_info.name}}</h3>
            <p>正文介绍</p>
            <div class="content" v-html="item_info.intro"></div>
        </div> -->
        <dpAudio v-if=" showDpAudio" :audioArr = "item_info.audio" :itemIndex="''" @getPause ='setPause' :pauseStatus="'true'"></dpAudio>
    </div>
</template>

<script>
import dpAudio from '../components/dpAudio.vue';
import {Swiper,SwiperSlide } from "swiper/vue";
import 'swiper/swiper.min.css';
export default {
    name: 'itemDetail',
    components: {
        dpAudio,
        Swiper,
        SwiperSlide
    },
    // setup(){
    //     const data = reactive({
    //         controlledSwiper: null
    //     })
    //     const onSwiper = {
    //         setControlledSwiper(swiper){
    //             data.controlledSwiper = swiper
    //         }
    //     }

    //     return {
    //         ...toRefs(data),
    //         ...onSwiper
    //     }
        
    // },
    // setup() {
    //   const onSwiper = (swiper) => {
    //     console.log(swiper);
    //   };
    //   const onSlideChange = () => {
    //     console.log('slide change');
    //   };
    //   return {
    //     onSwiper,
    //     onSlideChange,
    //   };
    // },  


    
    data () {
        return {
            currentIndex: 1,                  //当前选中的索引，1是图片，2是视频，默认是选中图片
            item_info:{},
            showDpAudio:false,
            titleimge:'',
            swiper: {}
        }
    },
    mounted () {

    },
    created () {
        this.getItem();
    },
    beforeRouteEnter(to, from, next) {
        next()//一定不要忘记写
    },
    // 点击后拿到数据返回给下单地址
    beforeRouteLeave (to, from, next) {
        if (to.name === 'home') {
        to.query.temp = '这里是参数，选中后的地址'
        }

        next()//一定不要忘记写
    },    
    methods: {
        setPause(val){
            console.log(val)
        },
        selecteFunc (index) {
            this.currentIndex = index;
        },
        onSwiper(swiper){
            this.swiper = swiper;
            console.log(swiper);
        },
        next(){
            // console.log( this.$refs.mySwiper);
            this.swiper.slideNext();

        },
        prev(){
            this.swiper.slidePrev();
        },   
        changeTitleimg(index){
            console.log(index);
            if(this.item_info.image[index] !== undefined){

                this.titleimge = this.weburl + this.item_info.image[index];
            }
        },
        // 获取该展项数据的方法
        getItem () {
            var url = '';
            var data = {};

            if(this.$route.query.item_id){
                url = "/qrcode/public/index.php/index/items/getItem";
                data = {
                    area:this.$area,
                    lang:this.$language,
                    item_id:this.$route.query.item_id
                };
            }else{
                url = "/qrcode/public/index.php/index/items/scanItem";
                data = {
                    area:this.$area,
                    lang:this.$language,
                    sn:this.$route.query.sn,
                    goods_sn:this.$route.query.goods_sn,
                };             
            }
            this.$http({
                method: "post",
                url: url,
                dataType:"json",
                data: data
            })
            .then( res => {
                console.log(res);
                if(res.data.status === 1){
                    this.item_info = res.data.result;
                    // this.audioArrItem = res.data.result.audio;
                    this.titleimge = this.weburl + this.item_info.image[0];
                    this.showDpAudio = true;
                }else {
                    this.$Message.error({
                        top: 100,
                        content: res.data.msg,
                    });
                }
            })
            .catch( () => {
                this.$Message.error({
                    content: '请联系管理员',
                });
            })   

        }
    },
    watch: {
        /* currentIndex (n,o) {
             // console.log(n);
         }*/
    },
    computed: {
        weburl () {
            return this.$store.state.weburl;
        },
        srcurl: function () {
            return this.$store.state.srcurl;
        }
    }
}
</script>
<style scoped lang="less">
    @import "../assets/less/common";
.van-icon{
    position: absolute;
    z-index: 10;
    top: 0.2rem;
    left: 0.2rem;
    padding: 3px;

      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 30px;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0px 1px 2px 0px rgb(200 200 200 / 50%), 0 6px 20px 0 rgb(0 0 0 / 0%);

}
.itemDetail{
    position: relative;
    padding-bottom: 1.37rem;
    overflow: auto;
}

.picSwipeContainer{
    width: 100%;
    height: 6rem;
    position: relative;
}
.titleimge{
    top: 2vw;
    left: 3vw;
    width: 94vw; 
    height: 4rem;
    box-shadow: 0px 0px 0px 0 rgb(0 0 0 / 50%), 1px 4px 8px 0 rgb(0 0 0 / 30%);
}
.thumbimg{
    width: 17.5vw; 
    height: 1.52rem;
    border: 1px solid #866B40;
    
}
.picSwipeBox{
    position: relative;
    margin-top: 0.26rem;
    width: 100vw;
    background-color: #DCD2C1;
    height: 1.72rem;
    .controller{
      top: 0;
      width: 100vw;
      .van-icon{
          border: 2px solid;
          margin: 0.2rem;
          top: 0.35rem;
      }
      .prev{
          left: 0rem;
      }
      .next{
        left: calc(100% - 1rem);
      }
    }
}
.swiper-container{
    // width: 75vw;
    margin-left: 12.5vw;
    margin-right: 12.5vw;
    height: 1.72rem;
  }
.swiper-slide {
    width: 18.5%;
    height: 1.52rem;
    padding: 0.1rem 0rem;
    // padding: 0.13rem 0rem;
    // position:relative;
  }

.itemDetailContent{
    width: 100vw;
    margin-top: 0.5rem;

    h3 {
        font-size: 0.38rem;
        text-align: center;
        color:@word-color;
        line-height: 0.5rem;
        margin-bottom: 0.15rem;
    }
    p {
         font-size: 0.35rem;
         color: #000;
         position: relative;
         margin-left: 0.22rem;
         font-weight: 500;
         &:before {
             bottom: -0.11rem ;
             content: '';
             width: 22%;
             height: 4px;
             background: @word-color;
             left:0;
             z-index:11;
         }
        &:after {
            bottom: -0.1rem ;
            content: '';
            width: 100%;
            height: 1px;
            background: #E3E3E3;
            left:0;
            z-index: 10;
        }
     }
}

.content{
    padding-left: 0.22rem;
    padding-right: 0.22rem;
    padding-top: 0.3rem;
    padding-bottom: 0.1rem;
    line-height: 0.6rem;
    font-size: 0.3rem;
    font-weight: 300;
    white-space: pre-wrap;
    text-align: justify;
    // font-family: 'fangsong';
}
</style>
<style lang="less">
.picSwipe1{
    .swiper-wrapper{
        flex-direction: row;
        justify-content: center;
    }
}

</style>
