import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Search from '../views/Search.vue'
import hallDetail from '../views/hallDetail'
import itemDetail from '../views/itemDetail'
import scanQRCode from '../views/scanQRCode'
import scanQRCode2 from '../views/scanQRCode2'
import message from '../views/message'
import msgDetail from '../views/msgDetail'
import demo from '../views/demo'
import demo2 from '../views/demo2'
import header from '../components/Header'
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/Search',
    name: 'Search',
    component: Search
  },
  {
    path: '/hallDetail',
    name: 'hallDetail',
    component: hallDetail
  },
  {
    path: '/itemDetail',
    name: 'itemDetail',
    component: itemDetail
  },
  {
    path: '/message',
    name: 'message',
    component: message
  },
  {
    path: '/msgDetail',
    name: 'msgDetail',
    component: msgDetail
  },
  {
    path: '/scanQRCode',
    name: 'scanQRCode',
    component: scanQRCode
  },
  {
    path: '/scanQRCode2',
    name: 'scanQRCode2',
    component: scanQRCode2
  },
  {
    path: '/demo',
    name: 'demo',
    component: demo
  },
  {
    path: '/demo2',
    name: 'demo2',
    component: demo2
  },
  {
    path: '/header',
    name: 'header',
    component: header
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
