import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios"
// import iView from 'iview'
// import 'iview/dist/styles/iview.css'
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'
import VueI18n from './language'
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.less';

import Vant from 'vant';
import 'vant/lib/index.css';
import adapter from "webrtc-adapter";
// WebRTC适配器
// import vConsole from '@/assets/js/vconsole.js'


const app = createApp(App)
app.config.globalProperties.$http = axios
// app.config.globalProperties.$area = '2'; //深圳
app.config.globalProperties.$area = '14'; //贵阳
app.config.globalProperties.$language= VueI18n.global.locale;

axios.defaults.baseURL = Object.is(process.env.NODE_ENV, 'production')? window.location.protocol + "//www.indoorlink.com" : '';
// axios.defaults.baseURL = 'http://192.168.0.99';
axios.defaults.headers.post["Content-type"] = "application/json";
axios.defaults.withCredentials = true;


app.use(Vant);
app.use(VueI18n);
app.use(Swiper).use(SwiperSlide);
// app.use(vConsole);
// app.use(iView);

// app.use(VueAwesomeSwiper);
app.use(adapter);
// app.use(BrowserQRCodeReader);
app.use(store).use(router).mount('#app')
