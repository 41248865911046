import { createStore } from 'vuex'

export default createStore({
  state: {
    // weburl:window.location.protocol + "//192.168.0.97/qrcode/public",
    // srcurl:window.location.protocol + "//192.168.0.97/qrcode/public",
    weburl:window.location.protocol + "//www.indoorlink.com/qrcode/public",
    srcurl:window.location.protocol + "//www.indoorlink.com/qrcode/public/index.php",        //资源路径
    currentMultiple: "1.0",                                             //播放器的当前播放倍数
    volume:50,                                                          //播放器的音量
    showChooseLang:false,                                               //是否显示多语言选择框，默认为不显示
    homeAudioData:"", //首页播放器的播放数据
    layout: 'list',
  },
  mutations: {
    set_currentMultiple(state,val){
      state.currentMultiple = val;
    },
    set_volume(state,val){
      state.volume = val;
    },
    set_showChooseLang(state,val){
      state.showChooseLang = val;
    },
    set_homeAudioData(state,val){
      state.homeAudioData = val;
    },
    set_layout(state,val){
      state.layout = val;
    }
  },
  actions: {
  },
  modules: {
  }
})
