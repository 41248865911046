<template>
  <div class="scanQRCode">
    <router-link to="/"><van-icon name="arrow-left" size="0.8rem" color="#fff" /></router-link>

    <video ref="video" id="video" poster="../assets/images/scanQRCode/loading.jpg"></video>
    <!-- <video ref="video" id="video" ></video> -->
  </div>
</template>

<script>

// import adapter from "webrtc-adapter";
// WebRTC适配器 只需要引入就ok
import { Toast } from 'vant';
import { BrowserCodeReader } from '@zxing/browser';
import { BrowserQRCodeReader } from "@zxing/browser";
// import { BrowserQRCodeReader } from "@zxing/library";
export default {
   name: 'scanQRCode2',
    data: () => ({
        textContent: undefined,
    }),
    created() {
      this.init();
    },
    mounted() {
      
    },
    onUnmounted(){
        this.codeReader.reset();
    },
    methods: {

      async init(){
        const codeReader = new BrowserQRCodeReader();
        const videoInputDevices = await BrowserCodeReader.listVideoInputDevices();
        const matcher = ({ label }) => /back|trás|rear|traseira|environment|ambiente/gi.test(label);
        const selectedDeviceId = videoInputDevices.find(matcher) || videoInputDevices.pop();
        // console.log(selectedDeviceId.deviceId);
        await codeReader.decodeFromVideoDevice(selectedDeviceId.deviceId, "video", (result, error) => {
          if (result) {
            // console.log(result);
            // this.textContent = result.text;

            var goods_sn = /[0-9]*$/.exec(result.text);
            if(goods_sn){
              Toast.loading({
                message: '识别成功',
                forbidClick: true,
              });
              this.$router.push({path:"/itemDetail",query:{goods_sn:goods_sn}});
            }

          }
          if (error && !error) {
              Toast.fail(error);
              // console.error(error);
          }
        });
      },
    },
};

</script>
<style lang="less" scoped>
.van-icon{
    position: absolute;
    z-index: 10;
    top: 0.2rem;
    z-index: 101;
}
.scanQRCode{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
}
video {
  position: absolute;
  top: 0;  
  width: 100%;
  height: 100%;
  background-color: black;
}
</style>