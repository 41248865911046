<template>
    <div class="chooseLang" @click="nextTimeFunc()">
        <div class="langContainer">
            <h5>请选择适用语言</h5>
            <h6>Please choose the appropriate language.</h6>
            <div class="nationalFlagContainer">
                <div class="nationalFlagItem">
                    <div class="nationalFlagSmallContainer"><img src="../assets/images/lang/China.png" class="flag" @click="chooseLang(0)"><img src="../assets/images/lang/bingo.png" class="bingo" v-if="lang == 0"></div>
                    <div class="langName">中文</div>
                </div>
                <!-- <div class="nationalFlagItem">
                    <div class="nationalFlagSmallContainer"><img src="../assets/images/lang/England.png" class="flag" @click="chooseLang(1)"><img src="../assets/images/lang/bingo.png" class="bingo" v-if="lang == 1"></div>
                    <div class="langName">English</div>
                </div>
                <div class="nationalFlagItem">
                    <div class="nationalFlagSmallContainer"><img src="../assets/images/lang/Korea.png" class="flag" @click="chooseLang(2)"><img src="../assets/images/lang/bingo.png" class="bingo" v-if="lang == 2"></div>
                    <div class="langName">한국어</div>
                </div>
                <div class="nationalFlagItem">
                    <div class="nationalFlagSmallContainer"><img src="../assets/images/lang/Japan.png" class="flag" @click="chooseLang(3)"><img src="../assets/images/lang/bingo.png" class="bingo" v-if="lang == 3"></div>
                    <div class="langName">わご</div>
                </div> -->
            </div>
            <!-- <div class="confirm" @click="confirm()">确&nbsp;&nbsp;&nbsp;&nbsp;定</div>
            <div class="nextTime" @click="nextTimeFunc()">下次再选</div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: "chooseLang",
    data: function () {
        return {
            lang:parseInt(window.localStorage.getItem("lang"))
        }
    },
    computed: {
        langIndex: function () {
            return parseInt(window.localStorage.getItem("lang"));
        }
    },
    methods: {
        nextTimeFunc: function () {
            this.$store.commit("set_showChooseLang",false);
        },
        chooseLang: function (index) {
            window.localStorage.setItem("lang", index);
            this.$router.go(0);
            // this.lang = index;
        },
        confirm: function () {
            window.localStorage.setItem("lang",this.lang);
            window.location.reload();
        }
    }
}
</script>

<style scoped lang="less">
    @import "../assets/less/common";
    .chooseLang{
        width: 100%;
        height: 100vh;
        position: fixed;
        z-index: 106;
        position: fixed;
        background-color: rgb(16 16 16 / 50%);
    }
    .langContainer{
        width: 4.54rem;
        height: 2.38rem;
        position: absolute;
        top: 0.66rem;
        left: 1.95rem;
        background-color: white;
        border-radius: 0.1rem;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .langContainer h5{
        font-size: 0.24rem;
        color: #282828;
        text-align: center;
        position: relative;
        width: 4.54rem;
        margin: 0.2rem auto 0rem;
    }
    .langContainer h6{
        font-size:0.16rem;
        line-height: 0.2rem;
        font-weight:400;
        color:rgba(185,185,185,1);
        text-align: center;
        position: relative;
        width: 4.54rem;
        margin: 0.12rem auto 0rem;
    }
    .nationalFlagContainer{
        width: 100%;
        height: 1.31rem;
        display: flex;
        position: relative;
        width: 4.54rem;
        margin-top: 0.2rem;
        flex-wrap: nowrap;
        justify-content: center; 
    }
    .confirm{
        width: 5.4rem;
        height: 0.88rem;
        background:linear-gradient(0deg,rgba(156,143,255,1) 0%,rgba(114,214,255,1) 100%);
        border-radius:0.44rem;
        text-align: center;
        line-height: 0.88rem;
        font-size: 0.36rem;
        font-weight:400;
        color:rgba(255,255,255,1);
        margin: 0 auto;
    }
    .nextTime{
        text-align: center;
        color: #4795F6;
        font-size: 0.24rem;
        font-weight:400;
        margin-top: 0.2rem;
    }
    .nationalFlagItem{
        width: 25%;
    }
    .nationalFlagSmallContainer{
        width: 0.72rem;
        height: 0.72rem;
        margin: 0rem auto;
        position: relative;
    }
    .langName{
        text-align: center;
        color: #4B4B4B;
        font-size: 0.18rem;
        font-weight:400;
        margin-top: 0.05rem;
    }
    .flag{
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        position: absolute;
        top: 0.01rem;
        left: 0.01rem;
    }
    .bingo{
        position: absolute;
        width: 0.19rem;
        height: 0.14rem;
        bottom: 0.02rem;
        right: 0;
    }
</style>
