<template>
    <div class="hallDetail" >
        <div class="hallDetail-img"><img :src="header_img" alt=""></div>
        <div :style="currentPlayStatus ? 'padding-bottom:1.37rem':''" style="height:calc(100vh - 4.3rem);overflow-y: scroll">
            <div class="itemContainer" v-for="(n,i) in videoLists" :key="i" >
                <div class="itemPhotoContainer" @click="linkToItemDetail(n.item_id)">
                    <img :src="weburl + n.thumb">
                </div>
                <div class="itemNameContainer" @click="linkToItemDetail(n.item_id)">
                    <p class="itemName">{{ n.name }}</p>
                    <span>{{ n.look }}次播放</span>
                </div>
                <div class="playOrPauseContainer" @click="playVideo(i)" >
                    <img :src="current_idx === i? video_btn_img: require('../assets/images/home/pauseBtn.png')">
                </div>
                <dpAudio v-if="currentPlayStatus && current_idx === i" :audioArr ="n.audio" :itemIndex="i" @getPause ='setPause' :pauseStatus="paused"></dpAudio>
            </div>
        </div>


    </div>
</template>

<script>
  import dpAudio from '../components/dpAudio.vue'
  import playImg from '../assets/images/home/playBtn.png';
  import pauseImg from '../assets/images/home/pauseBtn.png'
export default {
    name: "hallDetail",
    components: {
        dpAudio
    },
    inject:['stopPropagation'],
    data () {
        return {
            videoLists:[],
            currentAudioData:"",                    //当前音乐播放器需要的数据
            currentPlayStatus:false,                //播放器显示
            header_img :'',
            video_btn_img : pauseImg,
            current_idx: 0,
            paused:true //播放器按钮状态
        }
    },
    created () {
        this.getItemList();
    },
    methods: {
        setPause(value){
            //true 表示 不播放，false表示播放，因为这里记录的是子组件中的pause状态。
           this.current_idx = value.key;
           this.video_btn_img = value.paused ? pauseImg:playImg;

        },
      linkToItemDetail (id) {
          this.stopPropagation();
          this.$router.push({path:"/itemDetail",query:{item_id:id}});
      },
      // 请求该展馆所有展项（和展厅）的数据
      getItemList () {
        this.$http({
            method: "post",
            url: "/index/items/getItemList",
            dataType:"json",
            data:{
                area:this.$area,
                lang:this.$language,
                cid: this.$route.query.id
            }
        })
        .then( res => {
            if(res.data.status === 1){
                for( let  o  in res.data.result){
                    this.header_img = this.weburl + res.data.result[o].thumb;
                    this.videoLists = res.data.result[o].items;
                }
            }else {
                this.$Message.error({
                    top: 100,
                    content: res.data.msg,
                });
            }
        })
        .catch( () => {
            // console.log(err);
            this.$Message.error({
                content: '请联系管理员',
            });
        })
      },
        playVideo(i){
          this.stopPropagation();
          if(this.current_idx !== i){
              this.currentPlayStatus = false;
              this.paused = true;
          }
          setTimeout(()=>{
              this.current_idx = i;
              this.currentPlayStatus = true;
              this.paused = !this.paused ;
              this.video_btn_img = this.paused ? pauseImg : playImg;
          },10);//可以解决音频currentTime未定义


        }
    },
    computed: {
        weburl () {
        return this.$store.state.weburl;
        },
        srcurl: function () {
            return this.$store.state.srcurl;
        }
    }
}
</script>

<style scoped lang="less">
    @import "../assets/less/common";
    .hallDetail {
        /*padding-bottom: 1.37rem;*/
        .hallDetail-img {
            width:100%;
            margin-bottom: 0.1rem;
            height: 4.2rem;
            img{
                width: 100%;
                height: 100%;
                vertical-align: bottom;
            }
        }
        /*.hallName{
            font-size: 0.3rem;
            color: @word-color;
            padding-left: 0.32rem;
            letter-spacing: 0.05rem;
        }*/
        .itemContainer{
            width: 100%;
            height: 1.71rem;
            background: @white;
            display: flex;
            .box-sizing;
            border-bottom: 1px solid #EDEDED;
            padding: 0.35rem 0.22rem;
            .itemPhotoContainer{
                width: 1.8rem;
                height: 1.01rem;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .itemNameContainer{
                width: 4.6rem;
                margin-left: 0.2rem;
                position: relative;
                span {
                    color:#CBCBCB;
                    font-size: 0.24rem;
                    position: absolute;
                    bottom: 0;
                }
                 p {
                     margin-bottom: 0.1rem;
                     font-size: 0.3rem;
                     color: #4C4C4C;
                     white-space:nowrap;
                     text-overflow:ellipsis;
                     overflow:hidden;
                 }
            }
            .playOrPauseContainer{
                width: 1rem;
                /* background-color: bisque; */
                /*position: relative;*/
                text-align: center;
                img{
                    width: 0.64rem;
                    margin: 0.175rem auto;
                }
            }
        }
    }
.dpAudio{
  position: fixed;
  bottom: 0;
  z-index: 102;
}
</style>
